<template>
  <b-card :title="tableTitle">
    <div class="mb-2">
      <!-- filter -->
      <b-col md="6">
        <slot name="filter"></slot>
      </b-col>
      <div class="d-flex justify-content-between flex-wrap">
        <div>
          <b-button
            v-if="create == 'true'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-0"
            @click="gotoCreatePage()"
          >
            Create
          </b-button>
        </div>
        <!-- filter -->
        <b-form-group
          v-if="search == 'true'"
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size=""
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="8">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="'Search ' + buttonTitle"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tableBasicCompanyHouseV2"
    >
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(status_contribution)="data">
        <p class="text-capitalize">{{ data.item.status }}</p>
      </template>

      <!-- A virtual column -->
      <template #cell(action)="data">
        <div style="min-width: 200px">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            v-b-popover.hover.bottom="'Show'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="show == 'true'"
            @click="gotoShowPage(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Edit'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="update == 'true'"
            @click="gotoEditPage(data.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Delete'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="deletes == 'true'"
            @click="confirmText(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Accept'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="acceptContri == 'true'"
            @click="acceptContribution(data.item.id)"
          >
            <feather-icon icon="CheckSquareIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Decline'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="declineContri == 'true'"
            @click="declineContribution(data.item.id)"
          >
            <feather-icon icon="XCircleIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <span v-if="totalRows == 1" style="margin: 1rem"></span>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
            <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            />
        </b-form-group> -->

      <b-form-group
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="onTableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>
<style></style>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: [
    "tableTitle",
    "apiUrl",
    "perPage",
    "currentPage",
    "items",
    "fields",
    "totalRows",
    "apiEndpoint",
    "create",
    "update",
    "deletes",
    "show",
    "search",
    "verifiersadvrequest",
    "buttonTitle",
    "acceptContri",
    "declineContri",
  ],
  components: {
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-popover": VBPopover,
  },
  data() {
    return {
      errors: {},
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      pageOptions: [1, 10, 25, 50, 100],
      filter: "",
      sortDirection: "asc",
      sortBy: "",
      sortDesc: false,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      model: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    let _ = this;
    // Set the initial number of items
    EventBus.$on("changeValue", () => {
      _.$refs.tableBasicCompanyHouseV2.refresh();
    });
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
      this.model = {};
    },
    hideModalAdv() {
      this.$refs["my-modal-adv"].hide();
      this.model = {};
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.$refs.tableBasicCompanyHouseV2.refresh();
    },
    changePerPage() {
      console.log(this.onTablePerPage, "ini Ditable");
      this.$emit("changePerPage", this.onTablePerPage);
    },
    gotoEditPage(id) {
      let _ = this;
      window.location = "/" + _.$props.apiUrl + "/" + id;
    },
    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/" + id + "/show");
    },
    gotoCreatePage() {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/add");
    },

    confirmText(id) {
      this.$swal({
        title: "Are you sure ?",
        text: "want to delete this data  " + this.buttonTitle + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/" + this.$props.apiEndpoint + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success Deleted",
                text: "Data has been deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableBasicCompanyHouseV2.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
  watch: {
    onTableCurrentPage: function (newValue) {
      let _ = this;
      console.log(newValue, "newvalue");
      _.$emit("changeCurrentPage", newValue);
    },
    items: function (newValue) {
      console.log(newValue);
      _.totalRows = _.items.length;
    },
    filter: function (newValue) {
      let _ = this;
    },
  },
};
</script>

<style>
.b-popover {
  font-size: 9px;
}
</style>
